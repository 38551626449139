//User

//auth
export const SIGN_UP = 'SIGN_UP';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const LOG_OUT= 'LOG_OUT';
export const RESTART_CREDENTIALS = 'RESTART_CREDENTIALS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

//First assessment
export const SET_ACTUAL_FIRST_ASSESSMENT = 'SET_ACTUAL_FIRST_ASSESSMENT';
export const GET_FIRST_ASSESSMENT = 'GET_FIRST_ASSESSMENT';
export const GET_ALL_FIRST_ASSESSMENTS = 'GET_ALL_FIRST_ASSESSMENTS';
export const DELETE_FIRST_ASSESSMENT = 'DELETE_FIRST_ASSESSMENT';

export const SEND_FIRST_ASSESSMENT = 'SEND_FIRST_ASSESSMENT'
//UI
export const SET_ERROR = 'SET_ERROR'
export const CLEAN_ERROR = 'CLEAN_ERROR'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
