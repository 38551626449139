export const data = [
    {
        name: 'Práctica Lean-Agile',
        sendName: 'lean_agile',
        value: 1
    },
    {
        name: 'Entrenamiento',
        sendName: 'training',
        value: 1
    },
    {
        name: 'Mentoría',
        sendName: 'mentoring',
        value: 1
    },
    {
        name: 'Facilitación',
        sendName: 'facilitation',
        value: 1
    },
    {
        name: 'Coaching',
        sendName: 'coaching',
        value: 1
    },
    {
        name: 'Maestría Técnica',
        sendName: 'm_technique',
        value: 1
    },
    {
        name: 'Maestría de Transformación',
        sendName: 'm_transformation',
        value: 1
    },
    {
        name: 'Maestría de Negocio',
        sendName: 'm_deal',
        value: 1
    },

]